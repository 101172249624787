.app {
  text-align: center;
}

.logo {
  height: 40vmin;
  pointer-events: none;
}

.link-wrapper {
  display: flex;
  justify-content: space-between;
}

.link {
  padding: 1rem;
  color: rgb(42, 167, 255);
  text-decoration: none;
  font-size: 32px;
}

.title {
  font-size: 48px;
  font-weight: bold;
}

.subheading {
  font-size: 32px;
}

@media (prefers-reduced-motion: no-preference) {
  .logo {
    animation: logo-spin infinite 20s linear;
  }
}

.header {
  background-color: rgb(31, 31, 40);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
